<template>
  <div>
    <div
      class="brand-heading relative mx-auto cursor-pointer select-none border-b border-gray-200 transition-all duration-300"
      :class="booking.BookingType <= 1 ? ' mb-2' : ''"
      @click="openBookingDetails = true"
    >
      <div
        class="brand-body h-32 w-full bg-cover bg-center p-2 text-sm sm:h-48 sm:text-xl"
        :class="booking.BookingType <= 1 ? getBackground('booking' + booking.Id) + ' text-white' : 'text-brand-500'"
      >
        <div class="relative h-full min-h-full tracking-wider">
          <div class="flex h-full items-center justify-center">
            <div class="text-center">
              <div class="">
                <div class="mt-2 sm:mt-4">
                  <span v-if="booking.ExtraDetails === null">{{ booking.CourseName }}</span><span v-else>{{ booking.ExtraDetails.TitleDesc }}</span>
                </div>
                <div
                  style="opacity: 80%;"
                  class="mb-1"
                >
                  <span v-if="booking.BookingType <= 1 || booking.BookingType === undefined">{{ booking.Date | moment("HH:mm Do MMMM YYYY") }}</span><span v-else>{{ booking.ClubName }}</span>
                </div>
                <div
                  v-if="booking.Cancelled"
                  class="inline-block bg-red-600 px-2 text-xs text-white xs:text-base"
                  style="opacity: 90%;"
                >
                  Cancelled
                </div>
                <div
                  v-if="!booking.Cancelled && booking.ConfirmationStatus === 'NotConfirmed'"
                  class="inline-block bg-gray-300 px-2 text-xs text-brand-500 xs:text-base"
                  style="opacity: 90%;"
                >
                  Pending
                </div>
                <div
                  v-if="!booking.Cancelled && booking.ConfirmationStatus === 'ManualConfirmed'"
                  class="inline-block bg-brand-500 px-2 text-xs text-white xs:text-base"
                  style="opacity: 90%;"
                >
                  Confirmed
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hidden items-center justify-between">
        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Club
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.ClubName }}
          </div>
        </div>

        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Course
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.CourseName }}
          </div>
        </div>

        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Date
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.Date | moment("h:mma dddd, MMMM Do YYYY") }}
          </div>
        </div>

        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Players
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.NumberOfPeople }}
          </div>
        </div>

        <div class="p-3 px-4">
          <div class="text-brand-600">
            Booked by
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.BookedByUsername }}
          </div>
        </div>
      </div>

      <div
        v-if="booking.Cancellable || booking.Expired"
        class="hidden select-none p-1"
        style="background-color: #f6f6f6"
      >
        <div
          v-if="booking.Cancellable"
          class=" m-4"
        >
          <div
            class="brand-button transition-200 transition-ease-in-out inline-block cursor-pointer border-brand-800 p-1 px-3 text-center
            uppercase text-white transition-bg hover:text-white"
            @click="$parent.cancelBookingStates.openModal = true; $parent.cancelBookingStates.bookingToCancel = booking"
          >
            Cancel booking
          </div>
        </div>
        <div
          v-if="booking.Expired"
          class="m-4 my-2"
        >
          <div
            class="brand-button transition-200 transition-ease-in-out inline-block cursor-pointer border-brand-800 p-1 px-3 text-center
            uppercase text-white transition-bg hover:text-white"
            @click="bookAgain()"
          >
            Book again
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="openBookingDetails"
      class="brand-body fixed inset-x-0 bottom-0 z-40 flex pb-6 xxxs:px-1 xxs:px-4 sm:inset-0 sm:items-center sm:justify-center sm:p-0"
    >
      <div
        x-transition:enter="ease-out duration-300"
        x-transition:enter-start="opacity-0"
        x-transition:enter-end="opacity-100"
        x-transition:leave="ease-in duration-200"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0"
        class="fixed inset-0 transition-opacity"
      >
        <div class="absolute inset-0 z-20 bg-gray-500 opacity-75" />
      </div>

      <div
        x-transition:enter="ease-out duration-300"
        x-transition:enter-start="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        x-transition:enter-end="opacity-100 translate-y-0 sm:scale-100"
        x-transition:leave="ease-in duration-200"
        x-transition:leave-start="opacity-100 translate-y-0 sm:scale-100"
        x-transition:leave-end="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        class="z-30 w-full transform bg-white px-4 pt-5 shadow-xl transition-all sm:max-w-xl sm:p-6 sm:pb-3"
      >
        <div>
          <div class="mt-1 text-center">
            <div>
              <div
                class="brand-body"
              >
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fad"
                    data-icon="calendar-day"
                    class="h-6 w-6 text-green-600"
                    role="img"
                    viewBox="0 0 448 512"
                  ><g class="fa-group"><path
                    class="fa-secondary"
                    fill="currentColor"
                    d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm192 176a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16zm112-240h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
                    opacity="0.4"
                  /><path
                    class="fa-primary"
                    fill="currentColor"
                    d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
                  /></g></svg>
                </div>

                <h3 class="brand-heading mt-4 text-2xl font-medium leading-6 text-gray-900">
                  Booking Details
                </h3>

                <!-- <div class="mt-4">
                  <div class="text-base leading-5 text-gray-600">
                    Your booking has been successful!
                  </div>
                </div>
                   -->

                <div v-if="booking.BookingType === 0">
                  <div class="mt-4">
                    <div class="leading-tight">
                      {{ booking.ClubName }}
                    </div>
                    <div class="leading-tight">
                      {{ booking.CourseName }} - {{ booking.NumberOfPeople }} player<span v-if="booking.NumberOfPeople > 1">s</span> <span v-if="OfWhichJuniors > 0">({{ booking.OfWhichJuniors }} juniors)</span>
                    </div>
                    <div class="mt-1 text-xl leading-none tracking-wide text-brand-500 sm:text-2xl">
                      {{ booking.Date | moment("h:mma dddd, MMMM Do YYYY") }}
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="leading-tight">
                      Booked by
                    </div>
                    <div class="mt-1 text-xl leading-none tracking-wide text-brand-500 sm:text-2xl">
                      {{ booking.BookedByUsername }}
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div
                    v-if="booking.ExtraDetails !== null"
                    class="mt-4"
                  >
                    <div class="text-xl leading-none tracking-wide text-brand-500 sm:text-2xl">
                      {{ booking.ExtraDetails.TitleDesc }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="booking.BookingType === 1"
                  class="mt-4"
                >
                  <div class="leading-tight">
                    Date & Time
                  </div>
                  <div class="mt-1 text-xl leading-none tracking-wide text-brand-500">
                    {{ booking.Date | moment("h:mma dddd, MMMM Do YYYY") }}
                  </div>
                </div>

                <div class="mt-4">
                  <div class="leading-tight">
                    Booking code
                  </div>
                  <div class="mt-1 text-xl leading-none tracking-wide text-brand-500">
                    {{ booking.BookingRef }}
                  </div>
                </div>

                <div class="mt-4">
                  <div class="leading-tight">
                    Request Notes
                  </div>
                  <div class="mt-1 text-xl leading-none tracking-wide text-brand-500">
                    <span v-if="booking.Notes !== ''">{{ booking.Notes }}</span>
                    <span v-else>Empty</span>
                  </div>
                </div>

                <div
                  v-if="booking.CancellationNotes !== null && booking.CancellationNotes !== ''"
                  class="mt-4"
                >
                  <div class="leading-tight">
                    Cancellation Notes
                  </div>
                  <div class="mt-1 text-xl leading-none tracking-wide text-brand-500">
                    {{ booking.CancellationNotes }}
                  </div>
                </div>

                <div
                  v-if="booking.ConfirmationCode !== null && booking.ConfirmationCode !== ''"
                  class="mt-3"
                >
                  <div class="leading-tight">
                    Confirmation code
                  </div>
                  <div class="mt-1 text-xl leading-none tracking-wide text-brand-500 sm:text-2xl">
                    {{ booking.ConfirmationCode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="booking.Expired || !booking.Cancellable || booking.Cancelled"
          class="mt-5 sm:mt-8"
        >
          <!-- <div class="inline-block w-1/2 pr-2">
            <SecondaryButton
              :text="'Book again'"
              @click="bookAgain()"
            />
          </div> -->

          <div class="inline-block w-full">
            <PrimaryButton
              :text="'Close'"
              @click="openBookingDetails = false"
            />
          </div>
        </div>

        <div
          v-else
          class="mt-5 sm:mt-8"
        >
          <div class="">
            <PrimaryButton
              :text="'Close'"
              class="py-3"
              @click="openBookingDetails = false"
            />
          </div>
          <div class="my-2 md:mb-0">
            <SecondaryButton
              class="py-3"
              :text="'Cancel booking'"
              @click="$parent.cancelBookingStates.openModal = true; $parent.cancelBookingStates.bookingToCancel = booking; openBookingDetails = false;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '../../../app-routes';
import PrimaryButton from '../base/button/primaryButton.vue';
import SecondaryButton from '../base/button/secondaryButton.vue';

export default {

  components: {
    PrimaryButton,
    SecondaryButton,
  },

  props: {
    booking: {
      type: Object,
      default: null,
    },
  },


  data() {
    return {
      openBookingDetails: false,
      backgroundLoaded: false,
      logoError: false,
    };
  },

  computed: {
    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },

  created() {
    // this.booking.Type = 1;
    this.$store.commit('setOverridingClubId', this.clubId);
  },

  methods: {
    getBackground(className) {
      if (!this.backgroundLoaded) {
        const imageLink = this.getImageLink(this.booking);
        this.backgroundLoaded = true;
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `.${className} { background: linear-gradient(rgba(19, 33, 48, 0.1), rgba(19, 33, 48, 0.4)), url(${imageLink}); background-position: center; background-size: cover; background-size: 100%; transition: all 1s ease; -moz-transition: all 1s ease; -ms-transition: all 1s ease; -webkit-transition: all 1s ease; -o-transition: all 1s ease; } .${className}:hover { background-size: 105%; }`;
        document.getElementsByTagName('head')[0].appendChild(style);
      }

      return className;
    },

    getImageLink(booking) {
      if (booking.BookingType === 0) {
        return this.currentTheme.golfBookingImage;
      } if (booking.BookingType === 1) {
        return this.currentTheme.spaBookingImage;
      } if (booking.BookingType === 2) {
        return this.currentTheme.otherBookingImage;
      }

      return this.currentTheme.golfBookingImage;
    },

    bookAgain() {
      router.push(`/booking/clubs/${this.booking.ClubId}/${this.booking.CourseId}?Date=${this.booking.Date}&Players=${this.booking.NumberOfPeople}&Juniors=${this.booking.OfWhichJuniors}&BookAgain=true`);
    },
  },

};
</script>
