<template>
  <div
    class="text-gray-800"
  >
    <div
      v-if="loadingStates.booking"
      class="z-40 flex h-screen min-h-screen w-full min-w-full items-center justify-center"
    >
      <loadingSpinner class="mx-auto text-center" />
    </div>
    <div
      v-else
      class="mt-5"
    >
      <div :class="bookings.length > 0?'sm:mt-6 sm:mb-4':''">
        <div
          v-if="bookings.length > 0"
          class="brand-body mb-1 text-sm text-gray-600 sm:text-base"
        >
          Upcoming bookings
        </div>
        <div class="h-1 w-full border-b border-gray-200" />
        <div
          v-for="(booking) in bookings"
          :key="booking.Id"
          class="inline-block w-full"
        >
          <BookingCard
            :booking="booking"
          />
        </div>
      </div>
    </div>

    <div
      v-show="cancelBookingStates.openModal"
      x-show="open"
      class="fixed inset-x-0 bottom-0 z-30 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    >
      <div
        x-show="open"
        x-transition:enter="ease-out duration-300"
        x-transition:enter-start="opacity-0"
        x-transition:enter-end="opacity-100"
        x-transition:leave="ease-in duration-200"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0"
        class="fixed inset-0 transition-opacity"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75" />
      </div>

      <div
        v-if="cancelBookingStates.loading"
        class="z-40 flex h-screen min-h-screen w-full min-w-full items-center justify-center"
      >
        <loadingSpinner class="mx-auto text-center" />
      </div>

      <div
        v-if="!cancelBookingStates.loading"
        x-transition:enter="ease-out duration-300"
        x-transition:enter-start="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        x-transition:enter-end="opacity-100 translate-y-0 sm:scale-100"
        x-transition:leave="ease-in duration-200"
        x-transition:leave-start="opacity-100 translate-y-0 sm:scale-100"
        x-transition:leave-end="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        class="z-40 transform overflow-hidden bg-white shadow-xl transition-all sm:w-full sm:max-w-lg"
      >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg
                class="h-6 w-6 text-red-600"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 class="brand-heading text-lg font-medium leading-6 text-gray-900">
                <span v-if="cancelBookingStates.cancelled === undefined">Cancel Booking</span>
                <span v-if="cancelBookingStates.cancelled">Booking Cancellation Requested</span>
                <span v-if="cancelBookingStates.cancelled !== undefined && !cancelBookingStates.cancelled">Cancellation Failed</span>
              </h3>

              <div v-if="cancelBookingStates.cancelled === undefined">
                <div class="brand-body mt-2">
                  <p class="text-sm leading-5 text-gray-600">
                    Are you sure you want to cancel this booking? Your request will be sent to the club, and if accepted your points will be refunded immediately.
                  </p>
                </div>
                <!-- <div class="mt-2 text-red-700">
                  Cancellations are solely at the discretion of the club.
                </div> -->
              </div>

              <div v-if="cancelBookingStates.cancelled !== undefined">
                <div class="brand-body mt-2">
                  <p
                    v-if="cancelBookingStates.cancelled"
                    class="text-sm leading-5 text-gray-600"
                  >
                    Your cancellation request has been accepted, your points will be refunded immediately.
                  </p>
                  <p
                    v-if="!cancelBookingStates.cancelled"
                    class="brand-body text-sm leading-5 text-gray-600"
                  >
                    Sorry, we were unable to send your cancellation request. Please contact our customer service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <span
            v-if="cancelBookingStates.cancelled === undefined"
            class="flex w-full shadow-sm sm:ml-3 sm:w-auto"
          >
            <button
              type="button"
              class="brand-button focus:shadow-outline-red inline-flex w-full justify-center border border-transparent bg-red-600 px-3 py-2 text-lg font-medium leading-6 text-white
              shadow-sm transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none sm:text-base sm:leading-5"
              @click="ConfirmCancelBooking()"
            >
              Cancel booking
            </button>
          </span>
          <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
            <button
              type="button"
              class="brand-button-secondary focus:shadow-outline inline-flex w-full justify-center px-4 py-2 text-lg font-medium
               leading-6 transition duration-150 ease-in-out focus:border-blue-300 sm:text-base sm:leading-5"
              @click="cancelBookingStates.openModal = false; cancelBookingStates.bookingToCancel = undefined; cancelBookingStates.cancelled = undefined;"
            >
              Close
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingCard from '../../booking/bookingCard.vue';
import LoadingSpinner from '../../base/loadingSpinner.vue';
import services from '@/app/shared/services';

export default {

  components: {
    BookingCard,
    LoadingSpinner,
  },

  props: {
    bookings: {
      type: Array,
      default() { return []; },
    },
  },

  data() {
    return {
      loadingStates: {
        stats: false,
        achievementScore: true,
        bookings: true,
      },

      cancelBookingStates: {
        openModal: false,
        bookingToCancel: undefined,
        loading: false,
        cancelled: undefined,
      },

      previousBookingStates: {
        limit: 5,
        show: false,
        loading: false,
      },

      previousBookings: undefined,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  computed: {

  },

  methods: {
    getBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url(${process.env.VUE_APP_BASE_URL}'img/thegrove2.jpg'); background-size: cover; background-position: center;`;
    },

    getPreviousBookings() {
      this.previousBookingStates.loading = true;
      services.booking.previousBookings().then((data) => {
        this.previousBookings = data;
        this.previousBookings.reverse();
        this.previousBookingStates.show = true;
        this.previousBookingStates.loading = false;
      });
    },

    ConfirmCancelBooking() {
      this.cancelBookingStates.loading = true;
      services.booking.cancelBooking2(this.cancelBookingStates.bookingToCancel.Id, this.cancelBookingStates.bookingToCancel.BookingType).then((result) => {
        this.cancelBookingStates.cancelled = result.Success;

        if (result.Success) {
          for (let i = 0; i < this.bookings.length; i += 1) {
            if (this.bookings[i].Id === this.cancelBookingStates.bookingToCancel.Id) {
              this.bookings[i].Cancellable = false;
              this.bookings[i].Cancelled = true;
              break;
            }
          }
        }

        this.cancelBookingStates.loading = false;
      });
    },
  },
};
</script>
