<template>
  <div>
    <div
      v-if="submenuDropdownOpen"
      class="fixed left-0 top-0 z-10 h-screen w-screen"
      @click="submenuDropdownOpen = false"
    />

    <div
      v-if="!disableCheckout"
      class="block xs:hidden"
    >
      <SubMenu
        :sub-menu-items="subMenuItems"
        :default-view="view"
        @click="showView"
      />
    </div>

    <img
      id="header-image"
      :src="currentTheme.coverImage2"
      class="header-image hidden xs:block"
    >

    <div class="m-4 pb-4 xs:m-6">
      <MemberCard
        class="hidden xs:block"
        @showView="showView"
      />

      <div
        v-if="!disableCheckout"
        class="relative z-10 mx-auto hidden max-w-5xl text-gray-800 xs:block"
        style="margin-top: 145px;"
      >
        <SubMenu
          :sub-menu-items="subMenuItems"
          :default-view="view"
          @click="showView"
        />
      </div>
      <div
        v-else
        class="w-full md:h-32"
      />


      <!-- Dashboard -->
      <div
        v-show="view === 'Dashboard'"
        class="mx-auto max-w-5xl"
      >
        <div class="-m-4 mb-0 flex items-center xs:m-0 xs:mt-6 xs:w-full">
          <img
            class="inline-block h-56 w-8/12 object-cover object-center xs:h-64"
            :src="currentTheme.home.welcome"
          >

          <div
            class="-ml-28 inline-block w-9/12 px-4 py-3 xs:-ml-40 xs:px-5 xs:py-4 sm:px-6 sm:py-5 sm:pr-16"
            style="background-color: rgb(243, 243, 243);"
          >
            <div class="brand-heading tracking-wider text-gray-800 xs:text-2xl">
              Welcome {{ forename }}
            </div>
            <div class="brand-body mt-1 text-sm text-gray-700 xs:text-lg">
              {{ club.dashboard.message }}
            </div>
            <div v-if="!isExpired">
              <div
                v-if="packageName !== undefined && packageName !== null"
                class="mr-2 inline-block text-sm xs:hidden xs:text-base"
              >
                <div class="uppercase tracking-widest text-gray-600">
                  <div class="inline text-brand-800">
                    &bull;
                  </div>
                  {{ packageName }}
                </div>
              </div>
            </div>

            <div v-else>
              <div
                class="mr-2 inline-block text-sm xs:hidden xs:text-base"
              >
                <div
                  class="uppercase tracking-widest text-red-700"
                  @click="showView('Renew')"
                >
                  <div class="inline text-brand-800">
                    &bull;
                  </div>
                  Membership expired
                </div>
              </div>
            </div>
          </div>
        </div>

        <Alert
          v-if="!showSpeakToTheTeamMessage && dashboardTitle !== '' && dashboardTitle !== null && dashboardTitle !== undefined"
          :title="dashboardTitle"
          :message="dashboardMessage"
          class="mt-5"
        />

        <Bookings :bookings="bookings" />

        <div
          v-if="showSpeakToTheTeamMessage"
          class="mt-5 w-full border-brand-500 bg-white p-3"
        >
          <div class="w-full border p-4 py-3 text-center">
            <div v-if="!nearExpiry && !accountExpired">
              <div class="brand-heading text-lg text-brand-500">
                You're Running Low on Points
              </div>
              <div class="brand-body mt-1 text-sm text-gray-500">
                Please speak to a member of the team to renew or top-up your account
              </div>
            </div>
            <div v-else>
              <div
                v-if="!accountExpired && membershipType === 0"
                class="brand-heading text-lg text-brand-500"
              >
                Your Renewal is Due Soon
              </div>
              <div
                v-if="accountExpired"
                class="brand-heading text-lg text-brand-500"
              >
                Your Account has Expired
              </div>
              <div class="brand-body mt-1 text-sm text-gray-500">
                Please speak to a member of the team to renew your account and continue your membership
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-5 pb-6"
        >
          <div v-if="!isExpired">
            <div
              class="flex h-32 w-full cursor-pointer select-none items-center justify-center text-center md:h-40"
              :class="getBackground(widgets[0])"
              @click="navigate(widgets[0])"
            >
              <div>
                <div class="brand-heading mb-2 inline-block border-b-2 border-brand-100 px-4 text-xl tracking-wider text-white">
                  {{ widgets[0].title }}
                </div>
                <div class="brand-body text-base leading-none tracking-wider text-white opacity-75">
                  Make a Booking
                </div>
              </div>
            </div>

            <div
              v-if="widgets.length > 2"
              class="mt-5 flex"
            >
              <div
                class="mr-2 w-1/2"
              >
                <div
                  class="noSelect flex h-32 cursor-pointer select-none items-center justify-center bg-cover bg-center text-center md:h-40"
                  :class="getBackground(widgets[1])"
                  @click="navigate(widgets[1])"
                >
                  <div class="sm:flex">
                    <div>
                      <div class="brand-heading mb-2 inline-block border-b-2 border-brand-100 px-4 text-xl tracking-wider text-white">
                        {{ widgets[1].title }}
                      </div>
                      <div class="brand-body text-base leading-none tracking-wider text-white opacity-75">
                        Make a Booking
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ml-2 w-1/2">
                <div
                  class="noSelect flex h-32 cursor-pointer select-none items-center justify-center bg-cover bg-center text-center md:h-40"
                  :class="getBackground(widgets[2])"
                  @click="navigate(widgets[2])"
                >
                  <div class="sm:flex">
                    <div>
                      <div class="brand-heading mb-2 inline-block border-b-2 border-brand-100 px-4 text-xl tracking-wider text-white">
                        {{ widgets[2].title }}
                      </div>
                      <div class="brand-body text-base leading-none tracking-wider text-white opacity-75">
                        Make a Booking
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else
              class="mt-5 flex"
            >
              <div
                class="mr-2 w-1/2"
              >
                <div
                  class="flex h-32 cursor-pointer select-none items-center justify-center bg-cover bg-center text-center md:h-40"
                  :class="getBackground({ type: 'BookingHistory' })"
                  @click="navigate({ type: 'BookingHistory' })"
                >
                  <div class="sm:flex">
                    <div class="brand-heading px-4 text-xl tracking-wider text-white">
                      Booking History
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="!disableCheckout"
                class="ml-2 w-1/2"
              >
                <div
                  class="noSelect flex h-32 cursor-pointer select-none items-center justify-center bg-cover bg-center text-center md:h-40"
                  :class="getBackground({ type: 'Topup' })"
                  @click="navigate({ type: 'Topup' })"
                >
                  <div class="sm:flex">
                    <div class="brand-heading px-8 text-xl tracking-wider text-white">
                      {{ disablePoints ? 'Top-up Rounds' : 'Top-up Points' }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="ml-2 w-1/2"
              >
                <div
                  class="noSelect flex h-32 cursor-pointer select-none items-center justify-center bg-cover bg-center text-center md:h-40"
                  :class="getBackground({ type: 'Topup' })"
                  @click="navigate({ type: currentTheme.buddiesTextPlural })"
                >
                  <div class="sm:flex">
                    <div class="brand-heading px-8 text-xl capitalize tracking-wider text-white">
                      {{ currentTheme.buddiesTextPlural }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="widgets.length > 3"
              class="brand-button-secondary mt-5 flex w-full min-w-full cursor-pointer select-none items-center justify-center border bg-white text-center transition-all duration-500 hover:bg-white hover:text-secondary-300"
              @click="navigate(widgets[3])"
            >
              <div>Personal Training & Guest Passes</div>
              <div class="ml-2">
                <svg
                  class="h-4 w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style="margin-bottom: 3px"
                ><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M366.3 381.1c-8.758-3.811-14.42-12.45-14.42-21.1l.0004-80H24c-13.25 0-24-10.75-24-23.1s10.75-24 24-24h327.9l-.0004-79.1c0-9.547 5.66-18.19 14.42-22c8.754-3.811 18.95-2.075 25.94 4.41l112.1 104c10.23 9.5 10.23 25.69 0 35.19l-112.1 104C385.3 384.1 375.1 385.8 366.3 381.1z" /></svg>
              </div>
            </div>
          </div>

          <div
            v-else
          >
            <div
              v-if="hasSpa"
              class="flex h-32 w-full cursor-pointer select-none items-center justify-center bg-cover bg-center text-center"
              :class="getBackground({ type: 'Renew' })"
            >
              <div class="brand-heading text-2xl tracking-wider text-white">
                Your Membership has Expired
              </div>
            </div>
            <div
              v-else
              class="flex h-32 w-full cursor-pointer select-none items-center justify-center bg-cover bg-center text-center"
              :class="getBackground({ type: 'Renew' })"
              @click="showView('Renew')"
            >
              <div class="brand-heading text-2xl tracking-wider text-white">
                Renew Your Membership
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Renew -->
      <div v-show="view === 'Renew'">
        <Renew
          :available-packages="availablePackages"
        />
      </div>

      <!-- Top-up -->
      <div v-show="view === 'Top-up'">
        <TopUp
          :available-packages="availablePackages"
        />
      </div>

      <!-- Referrals -->
      <!-- <div v-show="view === 'Referrals'">
        <Referrals />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import services from '@/app/shared/services';
import moment from 'moment/moment';
import LoadingSpinner from '../../shared/components/base/loadingSpinner.vue';
import Alert from '../../shared/components/base/alert.vue';
import SubMenu from '../../shared/components/base/menu/subMenu.vue';
import TopUp from '../../shared/components/member/topUp/topUp.vue';
import Renew from '../../shared/components/member/renew/renew.vue';
import Bookings from '../../shared/components/member/bookings/bookings.vue';
import MemberCard from '../../shared/components/member/memberCard.vue';

export default {
  name: 'App',
  components: {
    LoadingSpinner,
    SubMenu,
    TopUp,
    Renew,
    Bookings,
    MemberCard,
    Alert,
  },

  data() {
    return {
      basket: '0',
      view: 'Dashboard',
      submenuDropdownOpen: false,
      ex1: { label: 'color', val: 25, color: 'orange darken-3' },
      ex2: { label: 'track-color', val: 75, color: 'green lighten-1' },
      ex3: { label: 'thumb-color', val: 50, color: 'brand' },
      bookings: [],
      loadingStates: {
        stats: true,
        achievementScore: true,
        bookings: true,
      },

      backgroundCSSLoaded: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      availablePackages: undefined,

      dashboardTitle: '',
      dashboardMessage: '',
    };
  },

  computed: {
    ...mapState({
      flexiPoints: state => state.user.flexiPoints,
      homeClubName: state => state.user.homeClubName,
      homeClubId: state => state.user.homeClubId,
      homePoints: state => state.user.homePoints,
      forename: state => state.user.forename,
      packageName: state => state.user.packageName,
      isExpired: state => state.user.IsExpired,
      homeClub: state => state.club.homeClubDetails,
      courses: state => state.club.homeClubDetails.Courses,
      hasRestaurant: state => state.club.hasRestaurant,
      additionalBookers: state => state.club.additionalBookers,
      completedFirstPayment: state => state.user.completedFirstPayment,
      membershipType: state => state.user.membershipType,
    }),

    subMenuItems() {
      const items = ['Dashboard', 'Renew'];

      if (this.club.joinerFee === undefined) {
        items.push('Renew');
      }

      if (!this.isExpired) {
        items.push('Top-up');
      }

      return items;
    },

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
      disableCheckout: 'disableCheckout',
      miscId: 'miscId',
      hasSpa: 'hasSpa',
      disablePoints: 'disablePoints',
      getMemberExpiryInfo: 'getMemberExpiryInfo',
    }),

    widgets() {
      return this.club.homeWidgets;
    },

    lowOnPoints() {
      return this.homePoints < 20;
    },

    nearExpiry() {
      return this.getMemberExpiryInfo.monthsUntilExpiry() <= 0;
    },

    accountExpired() {
      return this.completedFirstPayment && this.isExpired;
    },

    showSpeakToTheTeamMessage() {
      return this.completedFirstPayment && this.disableCheckout && (this.lowOnPoints || this.nearExpiry || (this.accountExpired && this.membershipType === 0));
    },
  },

  created() {
    this.assignDefaultView();
  },

  mounted() {
    document.title = `${this.club.clubName} Members Area`;
    const favicon = document.getElementById('favicon');
    if (favicon !== null) {
      favicon.href = `/img/icons/${this.club.clubId}.ico`;
    }

    this.getUpcomingBookings();
    this.getPackages();

    services.club.clubMessage(this.homeClubId, 1).then((result) => {
      this.dashboardMessage = result.message;
      this.dashboardTitle = result.title;
    });
  },

  methods: {
    navigate(widget) {
      if (widget.type === 'Golf') {
        this.$router.push(`/booking/clubs/${this.homeClubId}/${this.courses.filter(c => c.FacilityType === 0)[0].Id}?Date=${new moment().toISOString()}&Players=1&Juniors=0`);
      } else if (widget.type === 'Spa') {
        this.$router.push(`/spa/${this.homeClubId}/${this.courses.filter(c => c.FacilityType === 1)[0].Id}`);
      } else if (widget.type === 'Restaurant' || widget.type === 'Hotel' || widget.type === 'Personal Training & Guest Passes') {
        const booker = JSON.parse(JSON.stringify(this.additionalBookers)).filter(b => b.Title === widget.type)[0];
        this.$router.push({
          name: 'ecomSearch',
          params: {
            type: booker.Category, clubId: this.homeClubId, courseId: this.miscId, title: booker.DescriptiveTitle, description: booker.Description,
          },
        });
      } else if (widget.type === 'BookingHistory') {
        this.$router.push('/account/profile/BookingHistory');
      } else if (widget.type === 'Topup') {
        this.view = 'Top-up';
      } else if (widget.type === this.currentTheme.buddiesTextPlural) {
        this.$router.push(`/${this.currentTheme.buddiesTextPlural}`);
      }
    },

    getPackages() {
      services.checkout.availablePackages().then((data) => {
        this.availablePackages = data;
      });
    },

    additionalPoints() {
      if (!this.disableCheckout) {
        if (!this.isExpired) {
          this.view = 'Top-up';
          window.scrollTo(0, 0);
        } else {
          this.$router.push(`/booking/clubs/${this.homeClubId}`);
        }
      } else {
        this.$router.push(`/spa/${this.homeClub.Id}/${this.homeClub.Courses.filter(c => c.FacilityType === 1)[0].Id}`);
      }
    },

    assignDefaultView() {
      if (this.$route !== undefined && this.$route.params.view !== undefined) {
        const viewParam = this.$route.params.view;
        console.log(viewParam);
        if (viewParam === 'Bookings') {
          this.view = 'Bookings';
        } else if (viewParam === 'Renew') {
          this.view = 'Renew';
        } else if (viewParam === 'Topup') {
          this.view = 'Top-up';
        }
        // else if(viewParam === "Referrals"){
        //   this.view = "Referrals";
        // }
      }
    },

    getBackground(widget) {
      const className = `${widget.type}Background`;
      if (!this.backgroundCSSLoaded.includes(className)) {
        this.backgroundCSSLoaded.push(className);
        const style = document.createElement('style');
        style.type = 'text/css';
        const pathToImages = `${process.env.VUE_APP_BASE_URL}img/club${this.homeClubId}`;
        // style.innerHTML = `.${className} { background: linear-gradient(rgba(19, 33, 48, 0.4), rgba(19, 33, 48, 0.4)), url(); background-position: center !important; background-size: cover !important; background-size: 100%; transition: all 1s ease; -moz-transition: all 1s ease; -ms-transition: all 1s ease; -webkit-transition: all 1s ease; -o-transition: all 1s ease; } .${className}:hover { background-size: 110%; }`;
        style.innerHTML = `.${className} { background: linear-gradient(rgba(19, 33, 48, 0.25), rgba(19, 33, 48, 0.5)), url(${pathToImages}/home/${widget.type}.jpg); background-position: center; background-size: cover; background-size: 100%; transition: all 1s ease; -moz-transition: all 1s ease; -ms-transition: all 1s ease; -webkit-transition: all 1s ease; -o-transition: all 1s ease; } .${className}:hover { background-size: 110%; }`;
        document.getElementsByTagName('head')[0].appendChild(style);
      }

      return className;
    },

    showView(view) {
      this.view = view;

      // view-specific loading - todo: make this better
      if (view === 'Bookings') {
        this.getUpcomingBookings();
      }
    },

    getUpcomingBookings() {
      if (this.loadingStates.bookings) {
        services.booking.upcomingBookings().then((result) => {
          this.bookings = result.filter(b => b.ExtraDetails === null || (b.ExtraDetails !== null && !b.ExtraDetails.Fulfilled)).filter(b => b.BookingType !== 2 || (b.BookingType === 2 && !b.Cancelled));

          this.bookings.reverse();

          this.loadingStates.booking = false;
        });
      }
    },
  },
};
</script>
