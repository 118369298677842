<template>
  <div
    class="mx-auto mt-6 max-w-5xl overflow-hidden text-gray-800"
  >
    <div class="overflow-hidden text-gray-600">
      <div
        class="overflow-hidden bg-cover bg-center pt-8 text-gray-400 sm:pt-16"
        :style="'background-image: linear-gradient(rgba(21,26,47,0.1),rgba(21, 26, 47, 0.2)),url(' + currentTheme.renew.header + ');'"
      >
        <div
          class="brand-heading px-10 pb-12 pt-2 text-2xl text-white xs:text-3xl sm:pb-20 md:text-4xl"
        >
          {{ club.renew.title }}
        </div>
      </div>

      <div v-if="availablePackages !== undefined">
        <div
          class="packageBackground mt-4 flex flex-wrap justify-center pb-4 pt-8 md:p-8 md:pt-16"
          :style="getRenewalBackground()"
        >
          <Package3
            v-for="(clubPackage, index) in availablePackages.PackageGroups.Packages"
            :key="index"
            class="mb-12 w-full md:mx-6 md:w-1/2"
            :index="index"
            :club-package="clubPackage"
            :promocode="promocode"
            :home-club-id="homeClubId"
          />
        </div>
      </div>

      <div
        v-else
        class="mb-4 mt-16 w-full min-w-full"
      >
        <div class="mx-auto text-center">
          <LoadingSpinner />
          <div class="brand-body mt-2 text-sm">
            Loading renewal packages
          </div>
        </div>
      </div>

      <div class="mb-6 flex min-w-full items-center justify-center sm:mb-0 sm:mt-12">
        <img
          class="hidden object-cover sm:inline-block"
          :src="currentTheme.renew.body"
          style="height: 460px; width: 677px;"
        >

        <div class="">
          <div
            class="descBackground brand-heading ml-0 pr-0 text-center sm:m-24 sm:-ml-24 sm:mr-0 sm:p-10 sm:text-left"
            :style="getBackground()"
          >
            <div
              class="p-10 text-sm leading-snug text-white sm:p-0 sm:text-base sm:text-gray-800"
            >
              <span class="text-xl text-white sm:text-2xl sm:text-brand-800">{{ club.renew.body.charAt(0) }}</span>{{ club.renew.body.substring(1) }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="clubBackground mt-10 flex items-start justify-start sm:mt-6"
        :style="getClubBackground()"
      >
        <div
          class="ml-0 bg-white p-4 text-center text-lg shadow xs:m-10 xs:max-w-lg"
        >
          <div class="border border-gray-200 p-4 sm:p-8">
            <div class="brand-heading text-center text-3xl leading-none tracking-wide text-brand-500 xxs:text-4xl">
              {{ club.clubName }}
            </div>
            <div
              class="brand-body text-brand mt-6 pb-0 pl-0 text-gray-800 sm:text-xl"
            >
              {{ club.introduction }}
            </div>

            <div
              v-if="!club.disableLanding"
              class="mx-auto mt-6 text-center xs:w-64"
            >
              <router-link :to="'/Club/' + homeClubName + '/' + homeClubId">
                <PrimaryButton
                  :text="'Discover ' + club.clubNameShort"
                  text-size="'text-sm xs:text-lg sm:text-lg'"
                />
              </router-link>
            </div>

            <div
              v-if="club.disableLanding && club.website !== undefined"
              class="mx-auto mt-6 text-center xs:w-64"
            >
              <a
                :href="club.website"
                target="_blank"
              >
                <PrimaryButton
                  :text="'Discover ' + club.clubNameShort"
                  text-size="'text-sm xs:text-lg sm:text-lg'"
                />
              </a>
            </div>
          </div>
        </div>

        <!-- <img
          class="object-cover mt-6"
          src="/img/thegrove3.jpg"
          style="height: 320px; width: 465px;"
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Package3 from '../../package/package3.vue';
import LoadingSpinner from '../../base/loadingSpinner.vue';
import PrimaryButton from '../../base/button/primaryButton.vue';
import services from '@/app/shared/services';

import { nodeEnv } from '@/environment/environment';

export default {

  components: {
    LoadingSpinner,
    Package3,
    PrimaryButton,
  },

  props: {
    availablePackages: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      overlay: false,
      promocode: '',
      clubId: undefined,
      currentSubscription: undefined,
      environment: undefined,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      refreshingBasket: state => state.basket.refreshingBasket,
      homeClubId: state => state.user.homeClubId,
      homeClubName: state => state.user.homeClubName,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
      disableLanding: 'disableLanding',
    }),

  },

  watch: {
    basket(value) {
      if (value !== undefined && this.packageGroups === undefined) {
        this.getPackages();
      }
    },

    availablePackages() {
      if (this.availablePackages !== undefined) {
        this.packageGroups = this.availablePackages.packageGroups;
      }
    },
  },

  mounted() {
    this.environment = nodeEnv;

    services.payment.getActiveSubscription().then((data) => {
      this.currentSubscription = data;
    });
  },

  methods: {
    getClubBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url('${this.currentTheme.renew.club}'); background-size: cover; background-position: center;`;
    },

    getBackground() {
      return `background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('${this.currentTheme.renew.body}'); background-size: cover; background-position: center;`;
    },

    getRenewalBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url('${this.currentTheme.renew.packages}'); background-size: cover; background-position: center;`;
    },

    applyPromo() {
      this.packageGroups = undefined;
      this.getPackages();
    },

    openBasket() {
      this.$store.dispatch('setBasketMenuState', true);
    },

    isInBasket(clubPackage) {
      if (this.basket !== undefined && this.basket !== null && this.basket.BundleId === clubPackage.Id) {
        return true;
      }
      return false;
    },

    getPackages() {
      services.checkout.availablePackages(this.promocode, this.clubId).then((data) => {
        this.packageGroups = data.PackageGroups;
      });
    },
  },
};

</script>

<style>
  @media (max-width: 640px){
    .clubBackground {
      background: none !important;
    }
  }

  @media (min-width: 768px){
    .descBackground {
      background: none !important;
      background-color: #f3f3f3 !important;
    }
  }

</style>
