<template>
  <div>
    <div
      class="z-50 leading-normal"
      :class="enableSqueeze?'basketSqueeze':'basket'"
    >
      <div class="border border-b-0 bg-white shadow-lg">
        <div
          class="border-b p-2 px-3 tracking-wide"
          style="background: linear-gradient(to bottom, rgba(255,255,255,1), rgba(240,240,240,1));"
        >
          Basket
        </div>

        <div>
          <div
            v-if="basket === null || basket === undefined"
            class="p-3 text-center"
          >
            Empty
          </div>

          <div v-else>
            <div
              class="p-3 pt-2"
            >
              <div class="brand-heading text-xl tracking-wider text-brand-500">
                {{ basket.ClubName }}
              </div>

              <div class="border-b pb-4">
                <div class="mt-2 flex justify-between tracking-wide text-gray-900">
                  <div
                    v-if="basket.PackageType === 0"
                    id="packageDuration"
                  >
                    12 Month Package <span v-if="!completedFirstPayment && club.joinerFee !== undefined">+ Joiner Fee</span>
                  </div>
                  <div v-if="basket.PackageType === 1">
                    <div v-if="!disablePoints">
                      {{ basket.NewHomePoints }} <span v-if="false">Home </span>Points
                    </div>
                    <div v-else>
                      {{ basket.NewHomePoints }} Rounds
                    </div>
                  </div>
                  <div v-if="basket.PackageType === 2">
                    {{ basket.NewAwayPoints }} Flexi Points
                  </div>

                  <div id="cost">
                    <span
                      v-if="basket.BasePrice !== basket.NewPrice"
                      class=" line-through opacity-80"
                    >{{ basket.Currency.CurrencySymbol }}{{ basket.BasePrice + club.joinerFee }}</span>
                    {{ basket.Currency.CurrencySymbol }}{{ basket.NewPrice.toLocaleString() }}
                  </div>
                </div>

                <div class="flex justify-between text-sm leading-tight text-gray-600">
                  <div v-if="basket.PackageType === 0">
                    Valid until {{ basket.MembershipExtension.ExtendedRenewalDate | moment("Do MMMM YYYY") }}.
                  </div>
                  <div v-if="basket.PackageType === 1">
                    Additional <span v-if="false">Home </span><span v-if="!disablePoints">points</span><span v-else>rounds</span> for more golf at {{ basket.ClubName }}
                  </div>
                  <div v-if="basket.PackageType === 2">
                    Additional Flexi Points can be spent on rounds of golf at your home club and any other club in our network.
                  </div>
                  <div
                    class="removeFromBasket ml-3 cursor-pointer"
                    :class="refreshingBasket?'text-gray-400':'text-brand-500 hover:text-secondary-500'"
                    @click="emptyBasket()"
                  >
                    remove
                  </div>
                </div>
              </div>


              <div
                id="checkout"
                class="brand-button mt-4 cursor-pointer p-1 text-center transition duration-500 ease-in-out"
                @click="checkout()"
              >
                Checkout
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import router from '../../../app-routes';

export default {

  components: {

  },

  props: {
    enableSqueeze: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      refreshingBasket: false,
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      completedFirstPayment: state => state.user.completedFirstPayment,
    }),

    ...mapGetters({
      disablePoints: 'disablePoints',
      club: 'getClub',
    }),
  },

  mounted() {

  },

  methods: {
    checkout() {
      this.$store.dispatch('setBasketMenuState', false);
      router.push('/Checkout');
    },

    emptyBasket() {
      this.refreshingBasket = true;
      this.$store.dispatch('emptyBasket').then(() => {
        this.refreshingBasket = false;
      });
    },
  },

};

</script>


<style>
@media (min-width: 768px){
  .basket {
    position: absolute;
    top: 47px;
    right: -60px;
    width: 380px;
  }

  .basket:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
    margin-left: 300px;
    margin-top: -5px;
  }

}

@media (max-width: 768px){
  .basket {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100vw;
  }

  .basket:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
    margin-left: 110px;
    margin-top: -5px;
  }

}

@media (min-width: 768px){
  .basketSqueeze {
    position: absolute;
    top: 47px;
    right: -60px;
    width: 380px;
  }

  .basketSqueeze:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
    margin-left: 300px;
    margin-top: -5px;
  }
}

@media (max-width: 768px){
  .basketSqueeze {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100vw;
  }

  .basketSqueeze:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
    margin-left: 35px;
    margin-top: -5px;
  }
}

</style>
