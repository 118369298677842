import { clubList } from './lists/clubList';
import services from '@/app/shared/services';

const clubStore = {
  state: {
    overrideClub: false,
    overridingClubId: 0,
    homeClubDetails: null,
    hasRestaurant: true,
    additionalBookers: null,
  },

  getters: {
    getClub: (state, getters, rootState) => {
      if (state.overrideClub) {
        return clubList.find(club => club.clubId === state.overridingClubId) !== undefined
          ? clubList.find(club => club.clubId === state.overridingClubId)
          : clubList.find(club => club.clubId === 0);
      }
      return clubList.find(club => club.clubId === rootState.user.homeClubId) !== undefined
        ? clubList.find(club => club.clubId === rootState.user.homeClubId)
        : clubList.find(club => club.clubId === 0);
    },

    hasGolf: state => state.homeClubDetails !== null && state.homeClubDetails.Courses.filter(c => c.FacilityType === 0).length > 0,
    hasSpa: state => state.homeClubDetails !== null && state.homeClubDetails.Courses.filter(c => c.FacilityType === 1).length > 0,
    hasGym: state => state.homeClubDetails !== null && state.homeClubDetails.Courses.filter(c => c.FacilityType === 2).length > 0,
    miscId: state => state.homeClubDetails.Courses.filter(c => c.FacilityType === 3)[0].Id,
    hasRestaurant: state => state.hasRestaurant,
    disableCheckout: (state) => {
      if (state.homeClubDetails !== null) {
        return state.homeClubDetails.Courses.filter(c => c.FacilityType === 1).length > 0 || clubList.filter(c => c.clubId === state.homeClubDetails.Id)[0].disableCheckout;
      }
      return false;
    },
    disablePoints: state => state.homeClubDetails !== null && clubList.filter(c => c.clubId === state.homeClubDetails.Id)[0].disablePoints,
    disableLanding: state => state.homeClubDetails !== null && clubList.filter(c => c.clubId === state.homeClubDetails.Id)[0].disableLanding,
  },

  mutations: {
    setAdditionalBookers(state, value) {
      state.additionalBookers = value;
    },

    setClubDetails(state, value) {
      state.homeClubDetails = value;
    },

    setOverrideStyle(state, value) {
      if (typeof value === 'number') {
        const favicon = document.getElementById('favicon');
        favicon.href = `/img/icons/${value}.ico`;
      }

      state.overrideClub = value;
    },

    setOverridingClubId(state, value) {
      if (!(isNaN(value))) {
        state.overridingClubId = Number(value);
        state.overrideClub = true;
      } else {
        state.overridingClubId = 0;
      }
    },
  },

  actions: {
    populateAdditionalBookers(context, homeClubId) {
      if (homeClubId === 523) {
        services.ecom.listCategories(homeClubId, 661).then((data) => {
          context.commit('setAdditionalBookers', data.Items);
        });
      }
    },
  },
};

export { clubStore };
